<template>
    <div class="follow-user-button">
        <button class="btn btn-outline-primary" @click="followUser">
            {{ userToFollow.is_following ? "Following" : "Follow" }}
        </button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "FollowUserButton",
    props: {
        userToFollow: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        })
    },
    methods: {
        followUser() {
            if (!this.isLoggedIn) {
                this.$modal.show("auth-modal", { allowClose: true, showSignIn: true });
            } else {
                const isFollowing = !this.userToFollow.is_following;
                this.$emit("toggled-follow", isFollowing);
                this.$amplitude.logEvent(isFollowing ? "Follow" : "Unfollow", {
                    entity_id: this.userToFollow.id,
                    event_name: isFollowing ? "Follow" : "Unfollow",
                    title: this.userToFollow.displayname,
                    type: "users"
                });
                axios.post(`/users/${this.userToFollow.id}/follow`).then((response) => {
                    const isFollowingResponse = Boolean(response.data["is_following"]);
                    if (isFollowing != isFollowingResponse) {
                        this.$emit("toggled-follow", isFollowingResponse);
                    }
                }).catch((error) => {
                    console.log(error.response.data.errors.message);
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn {
    width: 144px;
}
</style>
